import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

const codeSchema = yup.object().shape({
  code: yup.string().required(),
});

const CodeForm = ({ setLead, confirmLead, code }: any) => {
  const [submit, setSubmit] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { getValues, errors, control, triggerValidation, setValue } = useForm({
    validationSchema: codeSchema,
  });

  useEffect(() => {
    setValue('code', code);
  }, [code, setValue]);

  useEffect(() => {
    if (submit && _.isEmpty(errors)) {
      (async function submitForm() {
        const formData = getValues();

        try {
          const result = await confirmLead({
            code: formData.code,
            leadId: _.trim(window.location.pathname, '/'),
          });
      
          setLead(result.data);
        } catch (error) {
          setServerError(error.message);
          setSubmit(false);
        }
      })();
    }

    if (submit && !_.isEmpty(errors)) {
      setSubmit(false);
    }
  }, [submit, errors, getValues, setLead, confirmLead])

  return (
    <Paper elevation={3} classes={{ root: 'Container2' }}>
      <form className="Form" noValidate autoComplete="off">
        <Controller
          as={(
            <TextField
              fullWidth
              label="Authorization Code"
              required
              error={!!errors.code}
              helperText={_.get(errors.code, 'message', '')}
            />
          )}
          name="code"
          control={control}
        />
      </form>
      <div className="Button">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={submit}
          onClick={() => {
            triggerValidation();
            setSubmit(true);
          }}
        >
            {submit ? (
              <div className="Loading">
                <RefreshIcon />
              </div>
            ) : (
              <CheckIcon  style={{ marginRight: '10px' }} />
            )}
          {submit ? 'Authorizing...' : 'Authorize'}
        </Button>
      </div>
      {!!serverError && (
        <div className="Error">
          {serverError}
        </div>
      )}
    </Paper>
  );
};

export default CodeForm;
