import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/functions';

import './App.css';
import logo from './lmnop-old-black.svg';
import { USDReturnsByMonth, PrecentageReturnsCompare, PrecentageReturnsPandora } from './Charts';
import LeadForm from './LeadForm';
import CodeForm from './CodeForm';
import { DataTable } from './DataTable';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE,
  authDomain: 'lmnop-abc.firebaseapp.com',
  projectId: 'lmnop-abc',
  databaseURL: 'https://lmnop-abc.firebaseio.com',
});

const firebaseFunction = firebase.app().functions('europe-west2');

const newLeadForm = firebaseFunction.httpsCallable('newLeadForm');
const confirmLead = firebaseFunction.httpsCallable('confirmLead');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const App = () => {
  const [code, setCode]: any = useState('');
  const [lead, setLead]: any = useState({});
  const [strategy, setStrategy] = useState('');

  const showForm = !_.isEmpty(lead);
  const showChart2 = strategy === 'luna' || strategy === 'nova';
  const showChart3 = strategy === 'pandora';

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    setCode(code);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <img className="Logo" src={logo} alt="LMNOP" />
        {showForm ? (
          <Grid
            container
            spacing={4}
          >
            <Grid item md={5} xs={12} lg={5}>
              <h3>{`New Customer Form ${lead.isCompleted ? '(Completed)' : ''}`}</h3>
              <LeadForm
                newLeadForm={newLeadForm}
                lead={lead}
                strategy={strategy}
                setStrategy={setStrategy}
              />
            </Grid>
            <Grid item md={7} xs={12} lg={7}>
              <h4>Actual Returns with 100BTC (1M USD)</h4>
              <div className="ChartContainer">
                <USDReturnsByMonth strategy={strategy} />
              </div>
              {showChart2 && (
                <>
                  <h4>LUNA vs NOVA - Comparable of USD Denominated Strategies</h4>
                  <div className="ChartContainer">
                    <PrecentageReturnsCompare />
                  </div>
                </>
              )}
              {showChart3 && (
                <>
                  <h4>PANDORA - BTC Denominated Strategy</h4>
                  <div className="ChartContainer">
                    {showChart3 && <PrecentageReturnsPandora />}
                  </div>
                </>
              )}
              <div className="DataContainer">
                <DataTable strategy={strategy} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <CodeForm
            setLead={setLead}
            confirmLead={confirmLead}
            code={code}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
