import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import {
  PersonalSection,
  StrategySection,
  BalanceBTCSection,
  BalanceUSDSection,
  StartSection, 
  SpotExchangeSection,
  DerivativeExchangeSection,
  AllocationSection,
  OrganizationSection,
} from './LeadFormSections';

const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

const LeadForm = ({ newLeadForm, lead, setStrategy, strategy }: any) => {
  const minimum = (lead.minimum || 10) - 1;
  const maximum = (lead.maximum || 100) + 1;

  const leadSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().matches(phoneRegExp, 'phone number is not valid').required(),
    address: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    postal: yup.string().required(),
    contactMethod: yup.string().required('contact method is required'),
    ai: yup.boolean().oneOf([true], 'you must be either an AI or QP'),
    balance: yup.number()
      .moreThan(minimum, `minimum ${lead.minimum} btc`)
      .lessThan(maximum, `maximum ${lead.maximum} btc`),
    balanceUSD: yup.number()
      .moreThan(minimum * 10000, `minimum ${lead.minimum * 10000} usd`)
      .lessThan(maximum * 10000, `maximum ${lead.maximum * 10000} usd`),
  });

  const defaultValues = {
    organization: _.get(lead, 'form.organization', '') || _.get(lead, 'organization', ''),
    strategy: _.get(lead, 'form.strategy', ''),
    balance: _.get(lead, 'form.balance', lead.minimum || 10),
    balanceUSD: _.get(lead, 'form.balanceUSD', ((lead.minimum || 10) * 10000)),
    start: _.get(lead, 'form.start', moment().toISOString()),
    sfox: _.get(lead, 'form.sfox', false),
    coinbase: _.get(lead, 'form.coinbase', false),
    gemini: _.get(lead, 'form.gemini', false),
    bitmex: _.get(lead, 'form.bitmex', false),
    spotAllocation: _.get(lead, 'form.spotAllocation', 100),
    name: _.get(lead, 'form.name', '') || _.get(lead, 'name', ''),
    email: _.get(lead, 'form.email', ''),
    phone: _.get(lead, 'form.phone', ''),
    address: _.get(lead, 'form.address', ''),
    city: _.get(lead, 'form.city', ''),
    state: _.get(lead, 'form.state', ''),
    postal: _.get(lead, 'form.postal', ''),
    contactMethod: _.get(lead, 'form.contactMethod', ''),
    ai: _.get(lead, 'form.ai', false),
  };

  const [completed, setCompleted] = useState(!!lead.isCompleted);
  const [submit, setSubmit] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { getValues, errors, control, triggerValidation, setValue } = useForm({
    defaultValues,
    validationSchema: leadSchema,
  });

  const [hasDerivative, setHasDerivative] = useState(defaultValues.bitmex);
  const [hasSpot, setHasSpot] = useState({
    sfox: defaultValues.sfox,
    coinbase: defaultValues.coinbase,
    gemini: defaultValues.gemini,
  });

  useEffect(() => {
    const formData = getValues();

    if (submit && _.isEmpty(errors)) {
      (async function submitForm() {
        try {
          await newLeadForm({
            code: lead.code,
            leadId: _.trim(window.location.pathname, '/'),
            form: formData,
          });

          setCompleted(true);
        } catch (error) {
          setServerError(error.message);
        }

        setSubmit(false);
      })();
    }

    if (submit && !_.isEmpty(errors)) {
      setSubmit(false);
    }
  }, [submit, errors, getValues, newLeadForm, lead]);

  const defaultProps = {
    errors,
    control,
    setValue,
    submit,
    completed,
    defaultValues,
    hasDerivative,
    setHasDerivative,
    hasSpot,
    setHasSpot,
    strategy,
    setStrategy,
    price: lead.price,
    lead,
  };

  const useUSD = strategy === 'luna' || strategy === 'nova';

  return (
    <form className="Form" noValidate autoComplete="off">
      <Grid container direction="column" justify="space-between">
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <OrganizationSection {...defaultProps} />
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <StrategySection {...defaultProps} />
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          {useUSD ? <BalanceUSDSection {...defaultProps} /> : <BalanceBTCSection {...defaultProps} />}
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <StartSection {...defaultProps} />
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <SpotExchangeSection {...defaultProps} />
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <DerivativeExchangeSection {...defaultProps} />
        </Grid>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" item>
          <AllocationSection {...defaultProps} />
        </Grid>
        <Grid container direction="column">
          <PersonalSection {...defaultProps} />
          <Fab
            style={{ marginLeft: '40px', marginRight: '40px', marginBottom: '10px' }}
            color={completed ? 'secondary' : 'primary'}
            variant="extended"
            onClick={() => {
              triggerValidation();
              setSubmit(true);
            }}
            disabled={submit || completed}
          >
            {submit ? (
              <div className="Loading">
                <RefreshIcon />
              </div>
            ) : (
              <CheckIcon  style={{ marginRight: '10px' }} />
            )}
            {submit ? 'Sending...' : (completed ? ' Form Submitted for review!' : 'Send Form')}
          </Fab>
          {completed && (
            <div className="Sent">
              {defaultValues.organization} - LMNOP will follow-up with your MSA through docusign. We look forward to this partnership!
            </div>
          )}
          {!!serverError && (
            <div className="Error">
              {serverError}
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default LeadForm;
