import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import accounting from 'accounting';
import _ from 'lodash';

import usdReturns from './returns.json';
import precentageReturns from './pref.json';

const chartData = [
  {
    id: 'LUNA',
    data: usdReturns.map(d => {
      return {
        x: d.Month,
        y: _.round(d.LUNA, 2),
      };
    }),
  },
  {
    id: 'NOVA',
    data: usdReturns.map(d => {
      return {
        x: d.Month,
        y: _.round(d.NOVA, 2),
      };
    }),
  },
  {
    id: 'Pandora',
    data: usdReturns.map(d => {
      return {
        x: d.Month,
        y: _.round(d.Pandora, 2),
      };
    }),
  },
  {
    id: 'BTC',
    data: usdReturns.map(d => {
      return {
        x: d.Month,
        y: _.round(d.BTC, 2),
      };
    }),
  },
];

const chartData3 = precentageReturns.map(d => {
  return {
    Month: d.Month,
    NOVA: parseFloat(d.NOVA),
    LUNA: parseFloat(d.LUNA),
  };
});

const chartData4 = precentageReturns.map(d => {
  return {
    Month: d.Month,
    PANDORA: parseFloat(d.PANDORA),
    PANDORAColor: "hsl(74, 70%, 50%)",
  };
});

const legends: any = [{
  dataFrom: 'keys',
  anchor: 'bottom-right',
  direction: 'row',
  justify: false,
  translateX: 20,
  translateY: 60,
  itemsSpacing: 0,
  itemDirection: 'left-to-right',
  itemWidth: 80,
  itemHeight: 20,
  itemOpacity: 0.75,
  symbolSize: 12,
  symbolShape: 'circle',
  symbolBorderColor: 'rgba(0, 0, 0, .5)',
  effects: [{
    on: 'hover',
    style: {
      itemBackground: 'rgba(0, 0, 0, .03)',
      itemOpacity: 1
    }
  }]
}];

const styleById: any = {
  highlight: {
    strokeWidth: 3,
  },
  default: {
    strokeWidth: 1,
  },
};

export const USDReturnsByMonth = ({ strategy }: any) => {
  const DashedLine = ({ series, lineGenerator, xScale, yScale }: any) => {
    return series.map(({ id, data, color }: any) => (
        <path
            key={id}
            d={lineGenerator(
                data.map((d: any) => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke={color}
            style={id.toLowerCase() === strategy ? styleById.highlight : styleById.default}
        />
    ));
  };

  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 20, right: 30, bottom: 60, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 700000, max: 1400000 }}
      yFormat={(value: any) => accounting.formatMoney(value)}
      colors={{ scheme: 'category10' }}
      pointSize={5}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh
      legends={legends}
      layers={['grid', 'markers', 'areas', DashedLine, 'slices', 'points', 'axes', 'legends']}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong>{point.serieId}</strong> {point.data.yFormatted}
              </div>
            ))}
          </div>
        )
      }}
    />
  );
};

export const PrecentageReturnsCompare = () => {
  return (
    <ResponsiveBar
      data={chartData3}
      keys={['LUNA', 'NOVA']}
      indexBy="Month"
      layout="horizontal"
      margin={{ top: 20, right: 30, bottom: 60, left: 46 }}
      padding={0.3}
      maxValue={35}
      minValue={-10}
      groupMode="grouped"
      colors={["#000", "#666666"]}
      labelSkipWidth={40}
      labelSkipHeight={12}
      labelTextColor="#fff"
      legends={legends}
      label={d => `${d.value}%`}
    />
  );
};

export const PrecentageReturnsPandora = () => {
  return (
    <ResponsiveBar
      data={chartData4}
      keys={['PANDORA']}
      indexBy="Month"
      layout="vertical"
      margin={{ top: 20, right: 30, bottom: 60, left: 20 }}
      padding={0.3}
      maxValue={6}
      minValue={-2}
      groupMode="grouped"
      colors="#000"
      labelSkipWidth={40}
      labelSkipHeight={12}
      labelTextColor="#fff"
      legends={legends}
      label={d => `${d.value}%`}
    />
  );
};
