import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import accounting from 'accounting';

import usdReturns from './returns.json';
import precentageReturns from './pref.json';

export const DataTable = ({ strategy }: any) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell classes={{ root: strategy === 'luna' ? 'LUNA' : '' }} align="right">LUNA</TableCell>
            <TableCell classes={{ root: strategy === 'nova' ? 'NOVA' : '' }} align="right">NOVA</TableCell>
            <TableCell classes={{ root: strategy === 'pandora' ? 'PANDORA' : '' }} align="right">Pandora</TableCell>
            <TableCell align="right">BTC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usdReturns.map((row, i) => (
            <TableRow key={row.Month}>
              <TableCell component="th" scope="row">
                {row.Month}
              </TableCell>
              <TableCell classes={{ root: strategy === 'luna' ? 'LUNA' : '' }} align="right">
                {accounting.formatMoney(row.LUNA)}
                <br/>
                ({precentageReturns[i].LUNA}%)
              </TableCell>
              <TableCell classes={{ root: strategy === 'nova' ? 'NOVA' : '' }} align="right">
                {accounting.formatMoney(row.NOVA)}
                <br/>
                ({precentageReturns[i].NOVA}%)
              </TableCell>
              <TableCell classes={{ root: strategy === 'pandora' ? 'PANDORA' : '' }} align="right">
                {accounting.formatMoney(row.Pandora)}
                <br/>
                ({precentageReturns[i].PANDORA}%)
              </TableCell>
              <TableCell align="right">
                {accounting.formatMoney(row.BTC)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
