import React, { useState } from 'react';
import 'date-fns';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import accounting from 'accounting';
import moment from 'moment';

import states from './states.json';
import notes from './notes.json';

const contactMethods = ['', 'email', 'phone', 'telegram', 'sms'];

export const StrategySection = ({ strategy, setStrategy, errors, control, setValue, submit, completed, defaultValues }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Choose Your Strategy</h4>
      <div className="Field">
        <div className="SelectInput">
          <Controller
            as={<></>}
            name="strategy"
            control={control}
          />
          <TextField
            select
            label="LMNOP Specific Strategies"
            error={!!errors.strategy}
            helperText={_.get(errors.strategy, 'message', ' ')}
            defaultValue={defaultValues.strategy}
            disabled={submit || completed}
            onChange={(event) => {
              setValue('strategy', event.target.value);
              setStrategy(event.target.value);
            }}
          >
            <MenuItem value="luna">
              LUNA (USD denominated low risk) 
            </MenuItem>
            <MenuItem value="nova">
              NOVA (USD denominated high risk)
            </MenuItem>
            <MenuItem value="pandora">
              PANDORA (BTC denominated) 
            </MenuItem>
            <MenuItem value="">
              Choose Later
            </MenuItem>
          </TextField>
          <FormHelperText>
            {notes.strategy}
          </FormHelperText>
          <h5 className={strategy === 'luna' ? 'LUNA' : ''}>LUNA (USD Denomination)</h5>
          <FormHelperText>
            {notes.luna}
          </FormHelperText>
          <h5 className={strategy === 'nova' ? 'NOVA' : ''}>NOVA (USD Denomination)</h5>
          <FormHelperText>
            {notes.nova}
          </FormHelperText>
          <h5 className={strategy === 'pandora' ? 'PANDORA' : ''}>PANDORA (BTC Denomination)</h5>
          <FormHelperText>
            {notes.pandora}
          </FormHelperText>
        </div>
      </div>
    </Paper>
  );
};

export const BalanceUSDSection = ({ price, errors, control, setValue, submit, completed, defaultValues, lead }: any) => {
  const [BTC, setBTC] = useState(defaultValues.balanceUSD / price.usd);
  const updated = moment(price.updated).format('MM/DD/YYYY');

  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Determine Your Starting Balance</h4>
      <div className="Field">
        <div className="NumberInput">
          <Controller
            as={<></>}
            name="balanceUSD"
            control={control}
          />
          <TextField
            defaultValue={defaultValues.balanceUSD}
            label="Starting USD Balance"
            type="number"
            disabled={submit || completed}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={(event: any) => {
              setValue('balanceUSD', event.target.value);
              setBTC(parseInt(event.target.value, 10) / price.usd);
            }}
            error={!!errors.balanceUSD}
            helperText={_.get(errors.balanceUSD, 'message', ' ')}
          />
          <FormHelperText>
            {`${BTC} BTC (coinmarketcap.com on ${updated})`}
          </FormHelperText>
          <br/>
          <FormHelperText>
            {`LMNOP has established that your minimum starting balance requirement is ${accounting.formatMoney(lead.minimum * 10000)} and your maximum is ${accounting.formatMoney(lead.maximum * 10000)}. You are able to deposit in any accepted pair on your exchange accounts.`}
          </FormHelperText>
        </div>
      </div>
    </Paper>
  );
};

export const BalanceBTCSection = ({ price, errors, control, setValue, submit, completed, defaultValues, lead }: any) => {
  const [USD, setUSD] = useState(defaultValues.balance * price.usd);
  const updated = moment(price.updated).format('MM/DD/YYYY');

  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Determine Your Starting Balance</h4>
      <div className="Field">
        <div className="NumberInput">
          <Controller
            as={<></>}
            name="balance"
            control={control}
          />
          <TextField
            defaultValue={defaultValues.balance}
            label="Starting BTC Balance"
            type="number"
            disabled={submit || completed}
            InputProps={{
              endAdornment: <InputAdornment position="end">BTC</InputAdornment>,
            }}
            onChange={(event: any) => {
              setValue('balance', event.target.value);
              setUSD(price.usd * parseInt(event.target.value, 10));
            }}
            error={!!errors.balance}
            helperText={_.get(errors.balance, 'message', ' ')}
          />
          <FormHelperText>
            {`${accounting.formatMoney(USD)} (coinmarketcap.com on ${updated})`}
          </FormHelperText>
          <br/>
          <FormHelperText>
            {`LMNOP has established that your minimum starting balance requirement is ${lead.minimum}BTC and your maximum is ${lead.maximum}BTC. You are able to deposit in any accepted pair on your exchange accounts.`}
          </FormHelperText>
        </div>
      </div>
    </Paper>
  );
};

export const StartSection = ({ errors, control, setValue, submit, completed, defaultValues }: any) => {
  const [selectedDate, setSelectedDate] = useState(defaultValues.start);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setValue('start', moment(date).toISOString());
  };

  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Choose Your Estimated Start Date</h4>
      <div className="Field">
        <div className="SelectInput">
          <Controller
            as={<></>}
            name="start"
            control={control}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label=""
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={submit || completed}
            />
          </MuiPickersUtilsProvider>
          <FormHelperText>
            {notes.start}
          </FormHelperText>
        </div>
      </div>
    </Paper>
  );
};

export const SpotExchangeSection = ({ errors, control, setValue, submit, completed, defaultValues, hasSpot, setHasSpot }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Choose Your Spot Exchanges</h4>
      <div className="Field">
        <Controller as={<></>} name="sfox" control={control} />
        <Controller as={<></>} name="coinbase" control={control} />
        <Controller as={<></>} name="gemini" control={control} />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultValues.sfox}
              disabled={submit || completed}
              onChange={(event, checked) => {
                setValue('sfox', checked);
                setHasSpot({
                  ...hasSpot,
                  sfox: checked,
                });
              }}
            />
          }
          label="SFOX"
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultValues.coinbase}
              disabled={submit || completed}
              onChange={(event, checked) => {
                setValue('coinbase', checked);
                setHasSpot({
                  ...hasSpot,
                  coinbase: checked,
                });
              }}
            />
          }
          label="Coinbase"
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultValues.gemini}
              disabled={submit || completed}
              onChange={(event, checked) => {
                setValue('gemini', checked);
                setHasSpot({
                  ...hasSpot,
                  gemini: checked,
                });
              }}
            />
          }
          label="Gemini"
        />
      </div>
      <div className="Field">
        <FormControlLabel
          control={
            <Checkbox disabled onChange={() => {}} />
          }
          label="Tagomi (beta)"
        />
        <FormControlLabel
          control={
            <Checkbox disabled onChange={() => {}} />
          }
          label="FalconX (beta)"
        />
        <FormControlLabel
          control={
            <Checkbox disabled onChange={() => {}} />
          }
          label="Binance (beta)"
        />
      </div>
      <FormHelperText>
        {notes.spotExchanges}
      </FormHelperText>
    </Paper>
  );
};

export const DerivativeExchangeSection = ({ errors, control, setValue, submit, completed, defaultValues, setHasDerivative }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Choose Your Derivative Exchange</h4>
      <div className="Field">
        <Controller as={<></>} name="bitmex" control={control} />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultValues.bitmex}
              disabled={submit || completed}
              onChange={(event, checked) => {
                setValue('bitmex', checked);
                setHasDerivative(checked);

                if (!checked) {
                  setValue('spotAllocation', 100);
                }
              }}
            />
          }
          label="Bitmex"
        />
        <FormControlLabel
          control={
            <Checkbox disabled onChange={() => {}} />
          }
          label="Bybit (beta)"
        />
      </div>
      <FormHelperText>
        {notes.derivativeExchanges}
      </FormHelperText>
    </Paper>
  );
};

export const AllocationSection = ({ errors, control, setValue, submit, completed, defaultValues, hasDerivative, hasSpot }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Prepare Your Allocations (Spot vs Derivatives)</h4>
      <Controller
        as={<></>}
        name="spotAllocation"
        control={control}
      />
      <Slider
        defaultValue={defaultValues.spotAllocation}
        getAriaValueText={(value: any) => `${value} / ${100 - value}`}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={10}
        min={0}
        max={100}
        marks={[
          {
            value: 10,
            label: '90% Derivatives',
          },
          {
            value: 50,
            label: '50 / 50',
          },
          {
            value: 90,
            label: '90% Spot',
          },
        ]}
        onChange={(event, value) => {
          setValue('spotAllocation', value);
        }}
        disabled={submit || completed || !hasDerivative || !(hasSpot.sfox || hasSpot.coinbase || hasSpot.gemini)}
      />
      <br/>
      <FormHelperText>
        {notes.allocation}
      </FormHelperText>
    </Paper>
  );
};

export const PersonalSection = ({ errors, control, setValue, submit, completed, defaultValues }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Signature Information</h4>
      <br/>
      <div className="Field">
        <Controller
          as={(
            <TextField
              fullWidth
              label="Full Name"
              required
              error={!!errors.name}
              helperText={_.get(errors.name, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="name"
          control={control}
        />
      </div>
      <div className="Field">
        <Controller
          as={(
            <TextField
              fullWidth
              label="Email"
              required
              error={!!errors.email}
              helperText={_.get(errors.email, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="email"
          control={control}
        />
      </div>
      <div className="Field">
        <Controller
          as={(
            <TextField
              fullWidth
              label="Phone"
              required
              error={!!errors.phone}
              helperText={_.get(errors.phone, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="phone"
          control={control}
        />
      </div>
      <div className="Field">
        <Controller
          as={(
            <TextField
              fullWidth
              label="Street Address"
              required
              multiline
              rowsMax="2"
              error={!!errors.address}
              helperText={_.get(errors.address, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="address"
          control={control}
        />
      </div>
      <div className="Field">
        <Controller
          as={(
            <TextField
              fullWidth
              label="City"
              required
              error={!!errors.city}
              helperText={_.get(errors.city, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="city"
          control={control}
        />
        <div className="SelectState">
          <Controller
            as={(
              <TextField
                select
                label="State"
                required
                error={!!errors.state}
                helperText={_.get(errors.state, 'message', ' ')}
                onChange={(event) => {
                  setValue('state', event.target.value);
                }}
                defaultValue=""
                disabled={submit || completed}
              >
                {states.map(option => (
                  <MenuItem key={option.abbreviation} value={option.abbreviation}>
                    {option.abbreviation}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="state"
            control={control}
          />
        </div>
        <Controller
          as={(
            <TextField
              fullWidth
              label="Zipcode"
              required
              error={!!errors.postal}
              helperText={_.get(errors.postal, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="postal"
          control={control}
        />
      </div>
      <div className="Field">
        <div className="SelectInput">
          <Controller
            as={(
              <TextField
                select
                label="Desired Contact Method"
                required
                error={!!errors.contactMethod}
                helperText={_.get(errors.contactMethod, 'message', ' ')}
                defaultValue=""
                disabled={submit || completed}
              >
                {contactMethods.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="contactMethod"
            control={control}
          />
        </div>
      </div>
      <div className="Field FieldSmall">
        <Controller
          as={(
            <FormControl
              required
              error={!!errors.ai}
              component="fieldset"
              disabled={submit || completed}
            >
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={defaultValues.ai} />
                }
                label="I hereby state that I am an accredited investor or qualified purchaser."
                classes={{ label: 'FieldSmall' }}
              />
              <FormHelperText>{_.get(errors.ai, 'message', 'LMNOP may require you to verify your accreditation status.')}</FormHelperText>
            </FormControl>
          )}
          name="ai"
          control={control}
        />
      </div>
    </Paper>
  );
};

export const OrganizationSection = ({ errors, control, setValue, submit, completed, defaultValues }: any) => {
  return (
    <Paper elevation={3} classes={{ root: 'Container' }}>
      <h4>Organization Information</h4>
        <Controller
          as={(
            <TextField
              fullWidth
              label="Organization"
              required
              error={!!errors.organization}
              helperText={_.get(errors.organization, 'message', ' ')}
              disabled={submit || completed}
            />
          )}
          name="organization"
          control={control}
        />
        <FormHelperText>
          {notes.organization}
        </FormHelperText>
    </Paper>
  );
};
